<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import EditData from "./edit-data"
import WorkOur from "./edit-workour"
/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Edit Location",
    meta: [{ label: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader, EditData, WorkOur },
  data() {
    return {
      title: "Form Edit Location",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Location",
          href: "/location"
        },
        {
          text: "Edit Location",
          active: true
        }
      ],
      tabActive: 0
    };
  },
  mounted() {

    switch(this.$route.hash) {
      case "#data":
        this.tabActive = 0
        break;
      case "#workhour":
        this.tabActive = 1
        break;
      default:
        this.tabActive = 0
        break;
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-tabs v-model="tabActive" justified nav-class="nav-tabs-custom" content-class="p-3">
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Data Location</span>
                </template>
                <EditData></EditData>
              </b-tab> 
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="far fa-envelope"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Setting Work Hours</span>
                </template>
                <WorkOur></WorkOur>
              </b-tab>
            </b-tabs>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>