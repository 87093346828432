<script>
import Swal from "sweetalert2";
import { required } from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
data() {
    return {
      center: { lat: 45.508, lng: -73.587 },
      checkedMarker: true,
      lattitude: null,
      longtitude: null,
      markers: [],
      places: [],
      currentPlace: null,
      form: {
        name: null,
        phone: null,
        latlong: [],
        address: null, 
      },
      typesubmit: false,
      isError: false,
      errorMessage: null,
    };
  },
  coordinates: null,
  validations: {
    form: {
      name: { required },
      address: { required },
      phone: { required },
    },
  },
  mounted() {
   
    this.fetchdata()
  },
  methods: {
    fetchdata(){
      this.$axios(`location/${this.$route.params.id}`)
      .then(response => {
        const result = response.data.result.location
        console.log(result)
        this.center = {
          lat: parseFloat(result.latitude),
          lng: parseFloat(result.longitude),
        };
        this.form.latlong.push({
          position: {
            lat: result.latitude,
            lng: result.longitude,
          },
        });
        this.form.address = result.address
        document.getElementById("valInput").value = result.address; 
        this.form.name = result.name
         this.form.phone = result.phone
      })
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    checkedInput(val) {
      // this.checked = val;
      // this.form.address = null;
      // this.form.latlong = [];
      
      if(val == ''){
             document.getElementById("valInput").value = this.form.address; 
      } else{
        this.form.address = document.getElementById("valInput").value
      }
   
        // console.log(a)
      // if(a === ''){
      //   console.log('nulll uy')
      // }
    
    },
    setPlace(place) {
      this.form.latlong = [];
      this.checkedMarker = true
      if (place) {
        const marker = {
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng(),
        };
        this.form.latlong.push({ position: marker });
      
        this.places.push(place);
        this.center = marker;
        place = null;
        this.form.address = this.places[0].formatted_address;
        console.log(this.places);
        console.log(this.form.latlong);
        this.places = [];
      }
    },

    updateCoordinates(location) {
      this.form.latlong = [];
      this.checkedMarker = false;
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };

      fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
          location.latLng.lat() +
          "," +
          location.latLng.lng() +
          "&key=AIzaSyCdUu51ehxVIQJvfaqQ64_VVV8Zt4fWdWs"
      )
        .then((response) => response.json())
        .then((data) => {
          console.log(data.results[0]);
          this.form.address = data.results[0].formatted_address;
          //  document.getElementById("valInput").value = ;
          this.form.address = data.results[0].formatted_address
          this.checkedInput(false)
          this.form.latlong.push({
            position: {
              lat: this.coordinates.lat,
              lng: this.coordinates.lng,
            },
          });
        });
      // this.setPlace()
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position);
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        this.form.latlong.push({
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
      });
    },

    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {
        let param = new FormData();
        param.append('name', this.form.name)
        param.append('phone', this.form.phone)
        param.append('address', this.form.address)
        param.append('latitude', parseFloat(this.form.latlong[0].position.lat))
         param.append('longitude', parseFloat(this.form.latlong[0].position.lng))
         
        this.$axios.put(`location/${this.$route.params.id}`, param)
        .then( (result) => {
          // console.log(result.data)
          // this.$router.push('/location')
           Swal.fire(
              "Success",
              "Location Data Updated Successfully",
              "success"
            );

        }).catch(e => {
          if (e.response) {
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    },
  },
};
</script>

<template>
 
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert v-model="isError" variant="danger" class="mt-3" dismissible>{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>Name</label>
                <input
                  v-model="form.name"
                  type="text"
                  class="form-control"
                  name="name"
                  :class="{ 'is-invalid': typesubmit && $v.form.name.$error }"
                />
                <div v-if="typesubmit && $v.form.name.$error" class="invalid-feedback">
                  <span v-if="!$v.form.name.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Phone</label>
                <input
                  v-model="form.phone"
                  type="number"
                  class="form-control"
                  name="phone"
                  :class="{ 'is-invalid': typesubmit && $v.form.phone.$error }"
                />
                <div v-if="typesubmit && $v.form.phone.$error" class="invalid-feedback">
                  <span v-if="!$v.form.phone.required">This value is required.</span>
                </div>
              </div>

              <div class="form-group">
                <label>Address</label>
                <div>
                  
                    <gmap-autocomplete
                      type="text"
                      id="valInput"
                      name="address"
                      autocomplete="off"
                      placeholder=""
                      class="form-control"
                      :class="{ 'is-invalid': typesubmit && $v.form.address.$error }"
                      @place_changed="setPlace"
                      v-on:keyup="checkedInput"
                      
                    ></gmap-autocomplete>
                  
                  <!-- <template v-else>
                    <input
                      v-model="form.address"
                      type="text"
                      class="form-control"
                      name="address"
                      v-on:keyup="checkedInput(true)"
                    />
                  </template> -->

                  <div v-if="typesubmit && $v.form.address.$error" class="invalid-feedback">
                    <span v-if="!$v.form.address.required">This value is required.</span>
                  </div>
                </div>
              </div>

              
              <div class="form-group">
                <br />

                <gmap-map :center="center" :zoom="12" style="width:100%;  height: 400px;">
                  <gmap-marker
                    :key="index"
                    v-for="(m, index) in form.latlong"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center=m.position"
                    @dragend="updateCoordinates"
                  ></gmap-marker>
                </gmap-map>
              </div>
              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit Change</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>

      <!-- end col-->
    </div>
  
</template>
