<script>
import DatePicker from "vue2-datepicker";
import * as moment from "moment";
import Switches from "vue-switches";
import Swal from "sweetalert2";

export default {
  components: { DatePicker, Switches },
  data() {
    return {
      workour: [
        {
          day: "monday",
          status: false,
          open: null,
          close: null,
          isOpenNotFilled: false,
          isCloseNotFilled: false,
        },
        {
          day: "tuesday",
          status: false,
          open: null,
          close: null,
          isOpenNotFilled: false,
          isCloseNotFilled: false,
        },
        {
          day: "wednesday",
          status: false,
          open: null,
          close: null,
          isOpenNotFilled: false,
          isCloseNotFilled: false,
        },
        {
          day: "thursday",
          status: false,
          open: null,
          close: null,
          isOpenNotFilled: false,
          isCloseNotFilled: false,
        },
        {
          day: "friday",
          status: false,
          open: null,
          close: null,
          isOpenNotFilled: false,
          isCloseNotFilled: false,
        },
        {
          day: "saturday",
          status: false,
          open: null,
          close: null,
          isOpenNotFilled: false,
          isCloseNotFilled: false,
        },
        {
          day: "sunday",
          status: false,
          open: null,
          close: null,
          isOpenNotFilled: false,
          isCloseNotFilled: false,
        },
      ],
      isError: false,
      errorMessage: null
    };
  },
  mounted() {
    this.getDetail()
  },
  methods: {
    getDetail() {
      this.$axios(`location/${this.$route.params.id}`)
      .then(response => {
        const workhour = response.data.result.location.workhour

        const hours = []
        for (const [key, value] of Object.entries(workhour)) {
          console.log(`${key}: ${value}`);
          let item = {
            day: key,
            status: value.status,
            open: value.open,
            close: value.close,
            isOpenNotFilled: false,
            isCloseNotFilled: false,
          }

          hours.push(item)
        }

        this.workour = hours
      })
    },
    onSubmit() {
      this.workour = this.workour.map( time => {
        if (time.status) {
          time.isOpenNotFilled = time.open === null ? true : false
          time.isCloseNotFilled = time.open === null ? true : false
        }

        return {
          day: time.day,
          status: time.status,
          open: time.open,
          close: time.close,
          isOpenNotFilled: (time.status && time.open === null)? true : false,
          isCloseNotFilled: (time.status && time.close === null)? true : false,
        }
      })

      let valid = true
      this.workour.forEach( time => {
        if (time.isOpenNotFilled || time.isCloseNotFilled) {
          valid = false
        }
      })

      if(!valid) return false

      let param = new FormData()
      this.workour.forEach( time => {
        switch(time.day) {
          case "monday":
            param.append('monday_status', time.status ? 1 : 0)
            param.append('monday_open', time.open)
            param.append('monday_close', time.close)
            break;
          case "tuesday":
            param.append('tuesday_status', time.status ? 1 : 0)
            param.append('tuesday_open', time.open)
            param.append('tuesday_close', time.close)
            break;
          case "wednesday":
            param.append('wednesday_status', time.status ? 1 : 0)
            param.append('wednesday_open', time.open)
            param.append('wednesday_close', time.close)
            break;
          case "thursday":
            param.append('thursday_status', time.status ? 1 : 0)
            param.append('thursday_open', time.open)
            param.append('thursday_close', time.close)
            break;
          case "friday":
            param.append('friday_status', time.status ? 1 : 0)
            param.append('friday_open', time.open)
            param.append('friday_close', time.close)
            break;
          case "saturday":
            param.append('saturday_status', time.status ? 1 : 0)
            param.append('saturday_open', time.open)
            param.append('saturday_close', time.close)
            break;
          case "sunday":
            param.append('sunday_status', time.status ? 1 : 0)
            param.append('sunday_open', time.open)
            param.append('sunday_close', time.close)
            break;
        }
          
      })

      this.$axios.put(`location/${this.$route.params.id}/workhour`, param)
        .then( () => {
           Swal.fire(
              "Success",
              "Workhour Updated Successfully",
              "success"
            );
        }).catch(e => {
          if (e.response) {
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
    }
  },
};
</script>
<template>
  <div>
    <b-alert v-model="isError" variant="danger" class="mt-3" dismissible>{{errorMessage}}</b-alert>
    <form action="#" @submit.prevent="onSubmit">
      <div class="form-group row" v-for="(item, i) in workour" :key="i">
        <div class="col-md-12 border-bottom">
          <div class="row">
            <div class="col-md-6">
              <h5 class="text-primary">{{ item.day | capitalize }}</h5>
            </div>
            <div class="col-md-6">
              <switches
                v-model="item.status"
                v-b-tooltip.hover
                title="Change Status"
                type-bold="false"
                color="warning"
                class="float-right pos-relative top-10"
              ></switches>
            </div>
          </div>
        </div>
        <template v-if="item.status">
          <div class="col-md-12 mt-10">
            <div class="row">
              <div class="col-md-6">
                <label>Open</label>
                <date-picker
                  v-model="item.open"
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  placeholder="HH:mm"
                  :class="{ 'is-invalid': item.isOpenNotFilled }"
                ></date-picker>
                <div v-if="item.isOpenNotFilled" class="invalid-feedback">
                  This value is required
                </div>
              </div>
              <div class="col-md-6">
                <label>Close</label>
                <date-picker
                  v-model="item.close"
                  format="HH:mm"
                  value-type="format"
                  type="time"
                  placeholder="HH:mm"
                  :class="{ 'is-invalid': item.isCloseNotFilled }"
                ></date-picker>
                <div v-if="item.isCloseNotFilled" class="invalid-feedback">
                  This value is required
                </div>
              </div>
            </div>
          </div>
        </template> 
      </div>
      <div class="row">
        <div class="col-md-12 pt-40">
          <b-button class="float-right" variant="primary" type="submit">Submit Change</b-button>
        </div>
      </div>
    </form>
  </div>
</template>